import { AddressFormData, AddressResponse, CountryType, Division, OpportunityFormData, OpportunityRequest, OpportunityResponse } from '@app/types'

import { formatSingleENUM, formatStringOption, formatAccountOption } from './formUtilities'
import { formatCampaignOption, formatContactOption, formatDivisionOption, formatLeadOption, formatOpportunityOption } from './selectOptions'
import { currencyToNumber } from './transformerUtilities'

export function prepareOpportunityFormDataForRequest(opportunity: OpportunityFormData): OpportunityRequest {
  // console.log('prepareOpportunityFormDataForRequest', opportunity)

  const {
    salesType,
    deliveryMethod,
    source,
    timeFrame,
    services,
    bidDueDate,
    estCloseDate,
    desiredProjectStartDate,
    desiredProjectEndDate,
    laborWarranty,
    materialWarranty,
    siteVisitsRequired,
    smallBusinessRequirements,
    bondsRequired,
    uniqueInsurance,
    laborShifts,
    cctPrefs,
    origin,
    account,
    competition,
    preferredSubcontractor,
    primaryStakeholder,
    parentOpportunity,
    parentType,
    quality,
    siteOwner,
    probability,
    stakeholders,
    escorts,
    estimators,
    preferredProductBrands,
    industries,
    accountPortfolioManager,
    businessDevelopmentManager,
    insideSalesRep,
    sites,
    createdFromLead,
    ...rest
  } = opportunity

  // console.log(rest)

  const preparedOpportunityRequest: OpportunityRequest = {
    ...rest,

    //required fields (temporary until create exists)
    stage: opportunity.stage ? opportunity.stage.value : 'SCOPE_DEVELOPMENT',
    name: opportunity.name,
    accountId: account?.value ?? null,
    primaryStakeholderId: primaryStakeholder?.value ?? null,
    parentOpportunityId: parentOpportunity?.value ?? null,
    parentType: parentType?.value && parentOpportunity?.value ? parentType?.value : null,
    salesType: salesType?.value ?? null,
    division: opportunity.division?.value ?? null,
    accountPortfolioManagerId: opportunity.accountPortfolioManager?.value ?? null,
    businessDevelopmentManagerId: opportunity.businessDevelopmentManager?.value ?? null,
    insideSalesRepId: opportunity.insideSalesRep?.value ?? null,

    //end required fields temp
    estimatorIds: opportunity.estimators?.map((estimator) => estimator.value) ?? null,
    stakeholderIds: opportunity.stakeholders?.map((stakeholder) => stakeholder.value) ?? null,
    laborWarranty: laborWarranty && laborWarranty >= 0 ? laborWarranty : null,
    materialWarranty: materialWarranty && materialWarranty >= 0 ? materialWarranty : null,
    otherCompliance: opportunity.otherCompliance?.map((opt) => opt.value) ?? null,
    safetyCompliance: opportunity.safetyCompliance?.map((opt) => opt.value) ?? null,
    californiaCompliant: opportunity.californiaCompliant?.map((opt) => opt.value) ?? null,
    dlcCompliance: opportunity.dlcCompliance?.map((opt) => opt.value) ?? null,
    lightLevels: opportunity.lightLevels?.value ?? null,
    onsiteStorage: opportunity.onsiteStorage?.value ?? null,
    onsiteLaborType: opportunity.onsiteLaborType?.value ?? null,
    drivingFactors: opportunity.drivingFactors?.map((opt) => opt.value) ?? null,
    serviceType: opportunity.serviceType?.value ?? null,
    liquidatedDamages: opportunity.liquidatedDamages ? currencyToNumber(opportunity.liquidatedDamages) : null,
    liquidatedDamagesUnitOfMeasure: opportunity.liquidatedDamagesUnitOfMeasure?.value ?? null,
    customerType: opportunity.customerType?.value ?? null,
    escortIds: escorts?.map((escort) => escort.value) ?? null,
    siteOwnerId: siteOwner?.value ?? null,
    preferredSubcontractorId: preferredSubcontractor?.value ?? null,
    competitionIds: competition?.map((competitor) => competitor.value) ?? null,

    // Fields that need to be transformed per request schema:
    deliveryMethod: deliveryMethod ? deliveryMethod.value : null,
    source: source?.value ? { source: source.value } : null,
    timeFrame: timeFrame ? { timeFrame: timeFrame.value } : null,
    services: services.map((service) => service.value) ?? null,
    bidDueDate: bidDueDate ?? null,
    estCloseDate: estCloseDate ?? null,
    desiredProjectStartDate: desiredProjectStartDate ?? null,
    desiredProjectEndDate: desiredProjectEndDate ?? null,
    siteVisitsRequired: siteVisitsRequired.map((visit) => visit.value) ?? null,
    smallBusinessRequirements: smallBusinessRequirements?.map((req) => req.value) ?? null,
    bondsRequired: bondsRequired?.map((bond) => bond.value) ?? null,
    uniqueInsurance: uniqueInsurance?.map((insurance) => insurance.value) ?? null,
    laborShifts: laborShifts?.map((shift) => shift.value) ?? null,
    cctPrefs: cctPrefs?.map((pref) => pref.value) ?? null,
    origin: origin?.map((ori) => ori?.value) ?? null,
    probability: parseFloat(probability?.value) ?? null,
    quality: quality ? quality.value : null,
    interiorSquareFootage: parseFloat(`${opportunity.interiorSquareFootage}`) || null,
    estimatedValue: opportunity.estimatedValue ? currencyToNumber(opportunity.estimatedValue) : null,

    //these are being ignored/overlooked for MVP. passing null values for now.
    materialMargin: null,
    laborMargin: null,
    generalConditionsMargin: null,
    overtimeAllowed: null,
    laborHourRounding: null,
    rebateFee: null,
    rebateFeeCap: null,
    preferredProductBrandsId: null,
    primaryInterest: null,
    //opportunity industries don't need to be set or manipulated. the field exists to show the industries for the 'Customer' account. We can just pass it a blank array indefinitely until its removed from the response/request (if ever)
    industries: [],
    siteIds: sites?.map((site) => site.value) ?? [],
    campaignId: opportunity.campaignId ? opportunity.campaignId.value : null,
    proposalValue: opportunity.proposalValue ? currencyToNumber(opportunity.proposalValue) : null
  }

  // console.log('prepared request', preparedOpportunityRequest)
  return preparedOpportunityRequest
}

export function prepareOpportunityResponseForFormData(
  opportunity: OpportunityResponse,
  { divisions }: { divisions: Division[] },
): OpportunityFormData {
  function getAddressFormData(address: AddressResponse): AddressFormData {
    return {
      id: address.id,
      label: formatStringOption(address.label),
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      state: formatStringOption(address.state),
      country: formatStringOption(address.country),
      postalCode: address.postalCode,
    }
  }

  const division = divisions?.find((d) => d.number === opportunity.division)

  const preparedOpportunityFormData: OpportunityFormData = {
    ...opportunity,
    laborWarranty: opportunity.laborWarranty ? parseInt(opportunity.laborWarranty) : null,
    materialWarranty: opportunity.materialWarranty ? parseInt(opportunity.materialWarranty) : null,
    preferredProductBrands: opportunity.preferredProductBrands
      ? { label: opportunity.preferredProductBrands.name, value: opportunity.preferredProductBrands.id }
      : null,
    competition: opportunity.competition?.map((comp) => ({ value: comp.id, label: comp.name, data: comp })) || null,
    preferredSubcontractor: opportunity.preferredSubcontractor
      ? {
          value: opportunity.preferredSubcontractor.id,
          label: `${opportunity.preferredSubcontractor.name}`,
        }
      : null,
    escorts: opportunity.escorts?.map((escort) => ({ label: `${escort.firstName} ${escort.lastName}`, value: escort.id })) || null,
    estimatedValue: opportunity.estimatedValue ? opportunity.estimatedValue.toString() : null,
    estimators: opportunity.estimators?.map(formatContactOption) || null,
    primaryStakeholder: opportunity.primaryStakeholder ? formatContactOption(opportunity.primaryStakeholder) : null,
    stakeholders: opportunity.stakeholders?.map(formatContactOption) || null,
    parentOpportunity: opportunity.parentOpportunity ? formatOpportunityOption(opportunity.parentOpportunity) : null,
    parentType: opportunity.parentType ? formatSingleENUM(opportunity.parentType) : null,
    siteOwner: opportunity.siteOwner ? formatAccountOption(opportunity.siteOwner) : null,
    accountPortfolioManager: opportunity.accountPortfolioManager ? formatContactOption(opportunity.accountPortfolioManager) : null,
    businessDevelopmentManager: opportunity.businessDevelopmentManager ? formatContactOption(opportunity.businessDevelopmentManager) : null,
    insideSalesRep: opportunity.insideSalesRep ? formatContactOption(opportunity.insideSalesRep) : null,
    account: opportunity.account ? formatAccountOption(opportunity.account) : null,
    otherCompliance: opportunity.otherCompliance?.map((compliance) => formatSingleENUM(compliance)),
    safetyCompliance: opportunity.safetyCompliance?.map((compliance) => formatSingleENUM(compliance)),
    californiaCompliant: opportunity.californiaCompliant?.map((compliant) => formatSingleENUM(compliant)),
    dlcCompliance: opportunity.dlcCompliance?.map((dlc) => formatSingleENUM(dlc)),
    lightLevels: opportunity.lightLevels ? formatSingleENUM(opportunity.lightLevels) : null,
    onsiteStorage: opportunity.onsiteStorage ? formatSingleENUM(opportunity.onsiteStorage) : null,
    onsiteLaborType: opportunity.onsiteLaborType ? formatSingleENUM(opportunity.onsiteLaborType) : null,
    drivingFactors: opportunity.drivingFactors?.map((factor) => formatStringOption(factor)),
    serviceType: opportunity.serviceType ? formatStringOption(opportunity.serviceType) : null,
    liquidatedDamagesUnitOfMeasure: opportunity.liquidatedDamagesUnitOfMeasure
      ? formatSingleENUM(opportunity.liquidatedDamagesUnitOfMeasure)
      : { value: 'DAY', label: 'Day' },
    liquidatedDamages: opportunity.liquidatedDamages ? opportunity.liquidatedDamages.toString() : null,
    division: division ? formatDivisionOption(division) : null,
    industries: opportunity.account?.industries?.length
      ? opportunity.account?.industries?.map((industry) => ({
          label: `${industry.title} (${industry.code})`,
          value: industry.title,
          description: industry.description,
        }))
      : null,
    customerType: opportunity.customerType ? formatStringOption(opportunity.customerType) : null,
    primaryInterest: opportunity.primaryInterest ? formatStringOption(opportunity.primaryInterest) : null,
    salesType: opportunity.salesType ? formatSingleENUM(opportunity.salesType) : null,
    deliveryMethod: opportunity.deliveryMethod ? formatSingleENUM(opportunity.deliveryMethod) : null,
    source: opportunity.source ? formatStringOption(opportunity.source.source) : null,
    timeFrame: opportunity.timeFrame ? formatStringOption(opportunity.timeFrame.timeFrame) : null,
    services: opportunity.services?.map((service) => formatStringOption(service.serviceType)) || [],
    bidDueDate: opportunity.bidDueDate ?? null,
    estCloseDate: opportunity.estCloseDate ?? null,
    desiredProjectStartDate: opportunity.desiredProjectStartDate ?? null,
    desiredProjectEndDate: opportunity.desiredProjectEndDate ?? null,
    siteVisitsRequired: opportunity.siteVisitsRequired?.map((visit) => formatSingleENUM(visit)) || [],
    smallBusinessRequirements: opportunity.smallBusinessRequirements?.map((obj) => formatSingleENUM(obj)) || [],
    bondsRequired: opportunity.bondsRequired?.map((obj) => formatSingleENUM(obj)) || [],
    uniqueInsurance: opportunity.uniqueInsurance?.map((obj) => formatSingleENUM(obj)) || [],
    laborShifts: opportunity.laborShifts?.map((obj) => formatSingleENUM(obj)) || [],
    cctPrefs: opportunity.cctPrefs?.map((obj) => formatSingleENUM(obj)) || [],
    origin: opportunity.origin?.map((obj) => formatSingleENUM(obj)) || [],
    stage: opportunity.stage ? formatSingleENUM(opportunity.stage) : null,
    probability: opportunity.probability ? { value: opportunity.probability.toString(), label: `${opportunity.probability}%` } : null,
    sites: opportunity.sites?.map((site) => ({ label: site.name, value: site.id, site: site })) || null,
    hardCopyNumbers: opportunity.hardCopyNumbers ?? 0,
    campaignId: opportunity.campaign ? formatCampaignOption(opportunity.campaign) : null,
    createdFromLead: opportunity.createdFromLead ? formatLeadOption(opportunity.createdFromLead) : null,
    quality: opportunity.quality?.rating ? formatStringOption(opportunity.quality.rating) : null,
    // propalValue should be nullable per FSG-8627
    proposalValue: opportunity.proposalValue ? opportunity.proposalValue.toString() : null
  }

  return preparedOpportunityFormData
}
