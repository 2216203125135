import { Coffee, Fire, Jollyroger, Snowflake } from '@fsg/icons'
import { cn } from '@fsg/utils'

import { IconOption } from '@app/types'

export function formatLeadQualityOption(option: IconOption) {
  const Icon = () => {
    switch (option.label) {
      case 'Warm':
        return <Coffee />
      case 'Hot':
        return <Fire />
      case 'Dead':
        return <Jollyroger />
      case 'Cold':
        return <Snowflake />
      default:
        return <Coffee />
    }
  }
  return (
    <div className={cn('flex items-center gap-3xs')}>
      <Icon />
      {option.label}
    </div>
  )
}
