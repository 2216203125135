import { NewSiteFormData } from 'src/app-components/Create/Site'

import { OPTIONS } from '@app/constants'
import { ADDRESS_LABEL, AddressFormData, AddressResponse, CountryType, NewSiteRequest, SiteFormData, SiteRequest, SiteResponse } from '@app/types'
import {
  formatContactOption,
  formatPaymentMethodOption,
  formatPhoneNumber,
  formatSiteTypeOption,
  formatStringOption,
  getAddressFormData,
  getAddressRequest,
  getPhoneRequest,
  setAddressIndex,
} from '@app/utils'

import { shippingProvidersMap } from '../constants/shippingProviders'
import { getSpecialChargeFormData } from './accountsTransformer'
import { currencyToNumber } from './transformerUtilities'

const termsMap = new Map(OPTIONS.NET_TERMS.map((x) => [x.value, x]))

export function prepareNewSiteFormDataForRequest(site: NewSiteFormData): NewSiteRequest {
  const { address, phone, ...rest } = site

  const newPhoneData = {
    label: 'Phone',
    number: phone,
    callingCode: site.callingCode,
    isPrimary: true,
    extension: site.extension,
  }

  const preparedNewSiteRequest = {
    ...rest,
    siteType: site.siteType?.value,
    phones: [getPhoneRequest(newPhoneData)],
    addresses: [
      {
        label: ADDRESS_LABEL.WORK_SITE,
        line1: address.line1,
        line2: address.line2 ?? '',
        city: address.city,
        state: address.state.value,
        postalCode: address.postalCode,
        country: address.country.value.name,
        isPrimary: true,
      },
    ],
    ownerId: site.ownerId.value,
  }
  return preparedNewSiteRequest
}

export function prepareSiteFormDataForRequest(site: SiteFormData): SiteRequest {
  // console.log('preparing data for request', site)
  //omit any properties that shouldn't exist in the SiteRequest
  const {
    owner,
    contacts,
    apContact,
    accountPortfolioManager,
    businessDevelopmentManager,
    insideSalesRep,
    transmissionRep,
    customerServiceRep,
    preferredTechnician,
    addOn,
    billingAddresses,
    primaryAddress,
    billingAddress,
    setPrimaryBillingAddress,
    setPrimarySiteAddress,
    ...rest
  } = site

  const preppedSiteAddresses = setAddressIndex(site.addresses, setPrimarySiteAddress, 'isPrimary')
  const preppedBillingAddresses = setAddressIndex(site.billingAddresses, setPrimaryBillingAddress, 'isBilling')
  const combinedAddresses = combineAddresses(preppedSiteAddresses, preppedBillingAddresses, site.billToMaster)
  const preparedSiteRequest = {
    ...rest,
    // ID transformations
    siteType: site.siteType?.value,
    contactIds: site.contacts.length > 0 ? site.contacts.map((contact) => contact.value) : [],
    ownerId: site.owner?.id,
    apContactId: site.apContact?.value,
    accountPortfolioManagerId: site.accountPortfolioManager?.value,
    businessDevelopmentManagerId: site.businessDevelopmentManager?.value,
    insideSalesRepId: site.insideSalesRep?.value,
    transmissionRepId: site.transmissionRep?.value,
    customerServiceRepId: site.customerServiceRep?.value,
    preferredTechnicianId: site.preferredTechnician?.value,
    // -----
    addresses: combinedAddresses?.map(getAddressRequest),
    holdPeriod: site.holdPeriod?.value,
    electricPaymentMethod: site.electricPaymentMethod?.value,
    lightingPaymentMethod: site.lightingPaymentMethod?.value,
    creditCardSurchargeType: site.creditCardSurchargeType ? { type: 'Charge' } : { type: 'Exempt' },
    addOnTypes: site.addOnTypes?.map((obj) => ({ addOnType: obj.label, value: parseInt(obj.value) })) || [],
    orderDisposition: site.orderDisposition?.value,
    specialCharge: site.specialCharge?.value,
    specialChargeAmount: site.specialChargeAmount ? currencyToNumber(site.specialChargeAmount) : null,
    status: site.status?.value,
    netTerms: site.netTerms?.value,
    sendInvoiceVia: site.sendInvoiceVia?.value,
    invoiceSchedule: site.invoiceSchedule?.value,
    freightTerms: site.freightTerms?.value,
    shipVia: site.shipVia?.value,
    billingCurrency: site.billingCurrency?.value,
    phones: site.phones && site.phones[0] != undefined ? site.phones?.map(getPhoneRequest) : [],
  }

  // console.log('prepped form data', preparedSiteRequest)
  return preparedSiteRequest
}

export function prepareSiteResponseForFormData(site: SiteResponse, { countries }: { countries: CountryType[] }): SiteFormData {
  function getBillingAddressFormData(address: AddressResponse): AddressFormData {
    const addressObject = {
      id: address.id,
      label: address?.label,
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      county: address.county ?? null,
      state: formatStringOption(address.state),
      country: formatStringOption(address.country),
      postalCode: address.postalCode,
      latitude: address.latitude,
      longitude: address.longitude,
      isPrimary: address.isPrimary ?? false,
      isBilling: address.isBilling ?? false,
    }
    // console.log('formatted address', addressObject)
    return addressObject
  }

  const { addresses: siteAddresses, billingAddresses } = splitAddresses(site.addresses)
  // console.log('preparing form data: ', site)
  const preparedSiteFormData = {
    ...site,
    status:
      site.status === 'true'
        ? { label: 'Active', value: 'Active' }
        : site.status === 'false'
        ? { label: 'Inactive', value: 'Inactive' }
        : site.status
        ? formatStringOption(site.status)
        : null,
    setPrimarySiteAddress: siteAddresses?.indexOf(siteAddresses.find((address) => address.isPrimary)),
    setPrimaryBillingAddress: billingAddresses?.indexOf(billingAddresses?.find((address) => address.isBilling)),
    contacts: site.contacts.length > 0 ? site.contacts.map((contact) => formatContactOption(contact)) : [],
    billToMaster: billingAddresses.length === 0 ? true : false,
    siteType: site.siteType ? formatSiteTypeOption(site.siteType) : null,
    phones: site.phones?.map((phone) => formatPhoneNumber(phone, { countries })),
    owner: site.owner ? site.owner : null,
    apContact: site.apContact ? formatContactOption(site.apContact) : null,
    accountPortfolioManager: site.accountPortfolioManager ? formatContactOption(site.accountPortfolioManager) : null,
    businessDevelopmentManager: site.businessDevelopmentManager ? formatContactOption(site.businessDevelopmentManager) : null,
    insideSalesRep: site.insideSalesRep ? formatContactOption(site.insideSalesRep) : null,
    transmissionRep: site.transmissionRep ? formatContactOption(site.transmissionRep) : null,
    customerServiceRep: site.customerServiceRep ? formatContactOption(site.customerServiceRep) : null,
    preferredTechnician: site.preferredTechnician ? formatContactOption(site.preferredTechnician) : null,
    specialCharge: site.specialCharge ? getSpecialChargeFormData(site.specialCharge) : null,
    specialChargeAmount: site.specialChargeAmount ? site.specialChargeAmount.toString() : null,
    addresses: siteAddresses?.map(getAddressFormData),
    billingAddresses: billingAddresses?.map(getBillingAddressFormData),
    electricPaymentMethod: site.electricPaymentMethod ? formatPaymentMethodOption(site.electricPaymentMethod) : null,
    lightingPaymentMethod: site.lightingPaymentMethod ? formatPaymentMethodOption(site.lightingPaymentMethod) : null,
    creditCardSurchargeType: site.creditCardSurchargeType?.type === 'Charge' ? true : false,
    orderDisposition: site.orderDisposition ? { label: site.orderDisposition?.value, value: site.orderDisposition } : null,
    addOnTypes: site.addOnTypes?.map((obj) => ({ value: obj.value.toString(), label: obj.addOnType })) || [],
    holdPeriod: site.holdPeriod ? { label: `${site.holdPeriod}`, value: site.holdPeriod } : null,
    netTerms: site.netTerms ? termsMap.get(site.netTerms) : null,
    sendInvoiceVia: site.sendInvoiceVia ? formatStringOption(site.sendInvoiceVia) : null,
    invoiceSchedule: site.invoiceSchedule ? formatStringOption(site.invoiceSchedule) : null,
    freightTerms: site.freightTerms ? formatStringOption(site.freightTerms) : null,
    shipVia: site.shippingProvider ? shippingProvidersMap.get(site.shippingProvider.toLowerCase()) : null,
    billingCurrency: site.billingCurrency ? formatStringOption(site.billingCurrency) : null,
  }
  // console.log(preparedSiteFormData)
  return preparedSiteFormData
}

function splitAddresses(addresses: AddressResponse[]): { addresses: AddressResponse[]; billingAddresses: AddressResponse[] } {
  const billingAddresses = addresses.filter(
    (address: AddressResponse) => address.isBilling === true || (address.isPrimary !== true && address.label !== 'Work Site'),
  )

  const rest = addresses.filter((address: AddressResponse) => address.isPrimary === true || address.label === 'Work Site')

  return { addresses: rest, billingAddresses: billingAddresses }
}

function combineAddresses(addresses: AddressFormData[], billingAddresses: AddressFormData[], billToMaster: boolean): AddressFormData[] {
  if (!billToMaster) {
    addresses.push(...billingAddresses)
  }

  return addresses
}
