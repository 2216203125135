import classNames from 'classnames'
import { ClassNamesConfig, StylesConfig } from 'react-select'

type Params = {
  classNames?: ClassNamesConfig<any>
  styles?: StylesConfig<any>
}
/**
@param {object} params - An object with properties `styles` and `classNames`, the same that would be passed directly to React-Select.

This utility function takes a style config object for React-Select and merges it with the default styles that customize the FSG design, with the passed-in styles overwriting the defaults.
*/
export function getSelectStyles(params?: Params) {
  // Default configs for FSG design
  // Due to specificity issues with React-Select using Emotion and classNames internally, we need to first reset any styles that we need to apply here, then provide the corresponding tailwind classes in the `defaultClassNames` object.
  const reset: StylesConfig = {
    multiValue: (base, props) => ({
      ...base,
      backgroundColor: undefined,
      border: undefined,
      borderRadius: undefined,
      ...params?.styles?.multiValue?.(base, props),
    }),
    singleValue: (base, props) => ({
      ...base,
      padding: undefined,
      ...params?.styles?.singleValue?.(base, props),
    }),
    option: (base, props) => ({
      ...base,
      fontSize: undefined,
      cursor: undefined,
      color: undefined,
      backgroundColor: undefined,
      borderRadius: undefined,
      width: 'auto',
      ...params?.styles?.option?.(base, props),
    }),
    menuList: (base, props) => ({
      ...base,
      minWidth: undefined,
      ...params?.styles?.menuList?.(base, props),
    }),
    menu: (base, props) => ({
      ...base,
      minWidth: undefined,
      borderRadius: undefined,
      ...params?.styles?.menu?.(base, props),
    }),
    multiValueLabel: (base, props) => ({
      ...base,
      padding: undefined,
      paddingLeft: undefined,
      color: undefined,
      fontSize: undefined,
      ...params?.styles?.multiValueLabel?.(base, props),
    }),
    control: (base, props) => ({
      ...base,
      borderColor: undefined,
      borderRadius: undefined,
      '&:hover': undefined,
      ...params?.styles?.control?.(base, props),
    }),
  }

  // Default classNames for FSG design
  const defaultClassNames: ClassNamesConfig = {
    control: (props) => classNames('rounded-xs pl-4xs border-gray hover:border-solid hover:border-color-primary', params?.classNames?.control?.(props)),
    valueContainer: (props) => classNames('text-sm', params?.classNames?.valueContainer?.(props)),
    option: (props) =>
      classNames(
        `text-sm cursor-pointer rounded-xs my-3xs disabled:bg-transparent w-[auto] ${
          props.isDisabled ? undefined : props.isSelected ? 'bg-blue-200' : props.isFocused ? 'bg-gray-lightest option-focused' : undefined
        }`,
        params?.classNames?.option?.(props),
      ),
    multiValue: (props) =>
      classNames('bg-white border border-solid border-gray rounded-2xs text-gray-darker py-3xs px-xs', params?.classNames?.multiValue?.(props)),
    multiValueLabel: (props) => classNames('font-semibold', params?.classNames?.multiValueLabel?.(props)),
    singleValue: (props) => classNames('py-xs', params?.classNames?.singleValue?.(props)),
    placeholder: (props) => classNames('text-gray-dark', params?.classNames?.placeholder?.(props)),
    menu: (props) => classNames('rounded-xs px-xs', params?.classNames?.menu?.(props)),
  }

  // Return the merged config as an object of react-select props to be spread into the given Select component
  // params go first because the defaults must apply any overriding properties individually, so they should override the params here
  return {
    styles: { ...params?.styles, ...reset } as any,
    classNames: { ...params?.classNames, ...defaultClassNames } as any,
  }
}
