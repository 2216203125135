import { AddressFormData, AddressResponse, ContactFormData, ContactRequest, ContactResponse, CountryType, PhoneFormData } from '@app/types'

import {
  compileRolesArray,
  formatPhoneNumber,
  getAddressFormData,
  getAddressRequest,
  getPhoneRequest,
  removeEmptyId,
  setAddressIndex,
  setPrimaryIndex,
} from './formUtilities'
import { formatContactOption } from './selectOptions'

export function prepareContactFormDataForRequest(contact: ContactFormData) {
  // omit any properties that are currently unavailable in the API
  const {
    connectedAccounts,
    businessDevelopmentManager,
    accountPortfolioManager,
    insideSalesRep,
    setPrimaryPhone,
    setPrimaryEmail,
    setPrimaryAddress,
    contactType,
    ...rest
  } = contact
  const roleFormData = {
    currentTitle: contact.currentTitle,
    currentCompany: contact.currentCompany,
    jobFunctions: contact.currentJobFunctions,
  }
  const preppedPhones = setPrimaryIndex(contact.phones, setPrimaryPhone)
  const preppedEmails = setPrimaryIndex(contact.emails, setPrimaryEmail)
  const preppedAddresses = setAddressIndex(contact.addresses, setPrimaryAddress, 'isPrimary') //since contacts dont implement a separation of primary/billing addresses, you can either pass undefined or -1 in the place of the expected billingIndex
  const preparedContactRequest: ContactRequest = {
    ...rest,
    // fields that need to be transformed per request schema:
    roles: compileRolesArray(roleFormData, contact.roles),
    businessDevelopmentManagerId: contact.businessDevelopmentManager?.value,
    accountPortfolioManagerId: contact.accountPortfolioManager?.value,
    insideSalesRepId: contact.insideSalesRep?.value,
    emails: preppedEmails?.length > 0 ? preppedEmails.map(removeEmptyId) : [],
    webLinks: contact.webLinks.length > 0 ? contact.webLinks.map(removeEmptyId) : [],
    addresses: preppedAddresses?.length > 0 ? preppedAddresses.map(getAddressRequest) : [],
    phones: preppedPhones?.length > 0 ? preppedPhones?.map((phone) => getPhoneRequest(phone as PhoneFormData)) : [],
    source: contact.source ? { source: contact?.source?.label } : null,
    fsgEmployeeId: contact.fsgEmployeeId ? contact.fsgEmployeeId : '',
    contactType: contactType ? contactType.id : null,
  }

  return preparedContactRequest
}

export function prepareContactResponseForFormData(contact: ContactResponse, { countries }: { countries: CountryType[] }): ContactFormData {
  const preparedContactFormData: ContactFormData = {
    ...contact,
    // fields that need to be transformed per form data schema:
    currentRole: contact.currentRole,
    roles: contact.roles,
    currentJobFunctions: contact.currentRole?.jobFunctions
      ? contact.currentRole.jobFunctions.map((obj) => ({
          value: obj.function,
          label: obj.function,
          type: obj.type,
          subType: obj.subType,
          function: obj.function,
        }))
      : [],
    currentCompany: contact.currentRole?.accountId ? { value: contact.currentRole.accountId, label: contact.currentRole.accountName } : null,
    currentTitle: contact.currentRole?.title,
    addresses: contact.addresses?.map(getAddressFormData),
    source: contact.source?.source ? { value: contact.source.source, label: contact.source.source } : null,
    setPrimaryPhone: contact.phones?.indexOf(contact.phones?.find((phone) => phone.isPrimary)),
    setPrimaryEmail: contact.emails?.indexOf(contact.emails?.find((email) => email.isPrimary)),
    setPrimaryAddress: contact.addresses?.indexOf(contact.addresses?.find((address) => address.isPrimary)),
    phones: contact.phones?.map((phone) => formatPhoneNumber(phone, { countries })),
    // temp properties to satisfy request schema
    contactType: null,
    inactiveReason: null,
    fsgEmployeeId: '',
    businessDevelopmentManager: contact.businessDevelopmentManager ? formatContactOption(contact.businessDevelopmentManager) : null,
    insideSalesRep: contact.insideSalesRep ? formatContactOption(contact.insideSalesRep) : null,
    accountPortfolioManager: contact.accountPortfolioManager ? formatContactOption(contact.accountPortfolioManager) : null,
  }

  return preparedContactFormData
}
