// REVIEW >> Bad import #code-review--agg-v30
// import { ICombinedSimpleModel, ISimpleFilterModelType } from 'ag-grid-community/dist/lib/filter/provided/simpleFilter'
import {
  AdvancedFilterModel,
  DateFilterModel,
  ICombinedSimpleModel,
  IMultiFilterModel,
  // ISimpleFilterModelType, // REVIEW >> Not in core #code-review--agg-v30
  NumberFilterModel,
  SetFilterModel,
  TextFilterModel,
} from '@ag-grid-community/core'

import { DATE_FILTER_OPERATION, FilterQueryParams, NUMBER_FILTER_OPERATION, TEXT_FILTER_OPERATION } from '../api/FilterQueryParams'

// ? AG Grid filter models
// https://www.ag-grid.com/react-data-grid/filter-api/#get--set-all-filter-models

// ? API filter operations
// https://github.com/fsgsmartbuildings/engage-api/blob/main/src/common/utils/parseFilterString.test.ts#L105-L120

type SimpleFilterModelTypes =
  | AdvancedFilterModel
  | SetFilterModel
  | TextFilterModel
  | NumberFilterModel
  | DateFilterModel
  | ICombinedSimpleModel<TextFilterModel | NumberFilterModel | DateFilterModel>

type FilterModelTypes = IMultiFilterModel | SimpleFilterModelTypes

/**
 * Converts the model returned by gridApi.getFilterModel() into a query string for use in entity API filter operations.
 *
 * @param filters - The AG Grid filter model returned by gridApi.getFilterModel().
 * @returns {string} A string combining query strings from each instance in the filter model
 */
export function convertFilterModelToApiQuery(filters: Record<string, FilterModelTypes>): string {
  if (Object.entries(filters).length === 0) return undefined
  const queries: string[] = []
  function buildQueriesFromProvidedFilters(fieldName: string, filterInstance: SimpleFilterModelTypes): string | null {
    if (filterInstance === null) return null
    const { filterType } = filterInstance
    if (filterType === 'set') {
      const { values } = filterInstance as SetFilterModel
      return getQueryFromSetFilterValues({ field: fieldName, values })
    } else if (filterType === 'text') {
      return buildQueriesFromTextFilterModel(fieldName, filterInstance as TextFilterModel | ICombinedSimpleModel<TextFilterModel>)
    } else if (filterType === 'number') {
      return buildQueriesFromNumberFilterModel(fieldName, filterInstance as NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>)
    } else if (filterType === 'date') {
      return buildQueriesFromDateFilterModel(fieldName, filterInstance as DateFilterModel | ICombinedSimpleModel<DateFilterModel>)
    } else {
      throw new Error(`Invalid filter type: ${filterType}`)
    }
  }

  for (const filterKey in filters) {
    const filterInstance = filters[filterKey]
    const { filterType } = filterInstance
    let query: string

    if (filterType === 'multi') {
      const { filterModels } = filterInstance as IMultiFilterModel
      query = filterModels
        .map((currentInstance) => buildQueriesFromProvidedFilters(filterKey, currentInstance))
        .filter((f) => f !== null)
        .join('&')
    } else {
      query = buildQueriesFromProvidedFilters(filterKey, filterInstance as SimpleFilterModelTypes)
    }

    // console.debug(`\t>> convertFilterModelToApiQuery() - ${query}`)
    queries.push(query)
  }

  const queryString = queries.join('&')
  // console.log('\t queryString: ', queryString)
  return queryString
}

/**
 * Maps an AG Grid Set Filter instance to an API filter query
 * @param {string} instance.field - The name of the field being filtered.
 * @param {string[]} instance.values - The values of the set filter.
 * @returns {string | null} The mapped string or null if the array is empty.
 */
function getQueryFromSetFilterValues({ field, values }: { field: string; values: string[] }) {
  // console.debug(`field: ${field} values: ${values}`)
  let queryString: string

  /*
    * SetFilterModel interface: {
      Filter type is always `'set'`
      * filterType?: 'set';
      String array of values from the options generated automatically from the data set
      * values: SetFilterModelValue; // string[]
    * }
  */
  if (values.length) {
    queryString = `${field}:in(${values.join(',')})`
  } else {
    queryString = `${field}:null()`
  }
  // console.debug(`\t>> ${queryString}`)
  return queryString
}

/**
 * Maps an AG Grid Text Filter to the corresponding API query using the FilterQueryParams class.
 *
 * @param {string} field - The name of the field being filtered.
 * @param {TextFilterModel} filterInstance.operation - The AG Grid Text Filter instance.
 *
 * @returns {string} The mapped API query string.
 *
 * @throws {Error} Throws an error if the provided AG Grid operation is unsupported.
 */
function getQueryFromTextFilterInstance(field: string, filterInstance: TextFilterModel) {
  const { type: operation, filter: value } = filterInstance

  const q = new FilterQueryParams()
  const queryString = q.textFilter({ field, operation: operation as TEXT_FILTER_OPERATION, value })

  return queryString
}

function buildQueriesFromTextFilterModel(fieldName: string, textFilterModel: TextFilterModel | ICombinedSimpleModel<TextFilterModel>) {
  let queryString = ''

  if (textFilterModel.hasOwnProperty('operator')) {
    /*
      * interface ICombinedSimpleModel<TextFilterModel> {
        Filter type is always `'text'`
        * filterType: string

        * operator: JoinOperator // => 'AND' or 'OR'

        multiple instances of the Filter Model
        * conditions: TextFilterModel[]
      }
      */
    const { operator, conditions } = textFilterModel as ICombinedSimpleModel<TextFilterModel>
    const joinedQueries = conditions
      .map((filterInstance) => getQueryFromTextFilterInstance(fieldName, filterInstance))
      .join(operator === 'AND' ? '&' : '|')
    queryString = `(${joinedQueries})`
  } else {
    /*
      * TextFilterModel interface: {
        Note: Same type for Text, Number and Date.
        Type here is the operation => 'equals', 'not equals', etc.
        * type?: ISimpleFilterModelType | null;

        Filter type is always `'text'`
        * filterType?: 'text';

        Filter is the text value associated with the filter.
        It's optional as custom filters may not have a text value.
        * filter?: string | null

        The 2nd text value associated with the filter, if supported.
        * filterTo?: string | null;
      * }
    */

    // here the entire model `textFilterModel` contains just one instance
    const query = getQueryFromTextFilterInstance(fieldName, textFilterModel as TextFilterModel)
    // console.debug(`\t>> ${query}`)
    queryString = query
  }

  return queryString
}

/**
 * Maps an AG Grid Number Filter to the corresponding API query using the FilterQueryParams class.
 *
 * @param {string} field - The name of the field being filtered.
 * @param {NumberFilterModel} filterInstance - The AG Grid Number Filter instance.
 *
 * @returns {string} The mapped API query string.
 *
 * @throws {Error} Throws an error if the provided AG Grid operation is unsupported.
 */
function getQueryFromNumberFilterInstance(field: string, filterInstance: NumberFilterModel) {
  const { type: operation, filter, filterTo } = filterInstance
  // https://www.ag-grid.com/react-data-grid/filter-number/#number-filter-options

  const q = new FilterQueryParams()

  const queryString =
    filterTo !== undefined
      ? q.numberFilter({ field, operation: operation as NUMBER_FILTER_OPERATION, value: [filter, filterTo] })
      : q.numberFilter({ field, operation: operation as NUMBER_FILTER_OPERATION, value: filter })

  return queryString
}

function buildQueriesFromNumberFilterModel(fieldName: string, numberFilterModel: NumberFilterModel | ICombinedSimpleModel<NumberFilterModel>) {
  let queryString = ''

  if (numberFilterModel.hasOwnProperty('operator')) {
    /*
      * interface ICombinedSimpleModel<NumberFilterModel> {
        Filter type is always `'number'`
        * filterType: string

        * operator: JoinOperator // => 'AND' or 'OR'

        multiple instances of the Filter Model
        * conditions: NumberFilterModel[]
      }
    */
    const { operator, conditions } = numberFilterModel as ICombinedSimpleModel<NumberFilterModel>
    const joinedQueries = conditions
      .map((filterInstance) => getQueryFromNumberFilterInstance(fieldName, filterInstance))
      .join(operator === 'AND' ? '&' : '|')
    queryString = `(${joinedQueries})`
  } else {
    /*
      * NumberFilterModel interface: {
        Note: Same type for Text, Number and Date.
        Type here is the operation => 'equals', 'not equals', etc.
        * type?: ISimpleFilterModelType | null;
        
        Filter type is always `'number'`
        * filterType?: 'number';

        The number value(s) associated with the filter.
        Custom filters can have no values (hence both are optional).
        Range filter has two values (from and to).
        * filter?: number | null;

        Range filter `to` value.
        if type === 'inRange', then this would be the second value of the range
        * filterTo?: number | null;
      * }
    */

    // here the entire model `numberFilterModel` contains just one instance
    const query = getQueryFromNumberFilterInstance(fieldName, numberFilterModel as NumberFilterModel)
    queryString = query
  }

  return queryString
}

/**
 * Maps an AG Grid Date Filter to the corresponding API query using the FilterQueryParams class.
 *
 * @param {string} field - The name of the field being filtered.
 * @param {DateFilterModel} filterInstance - The AG Grid Date Filter instance.
 *
 * @returns {string} The mapped API query string.
 *
 * @throws {Error} Throws an error if the provided AG Grid operation is unsupported.
 */
function getQueryFromDateFilterInstance(field: string, filterInstance: DateFilterModel) {
  const { type: operation, dateFrom, dateTo } = filterInstance

  // https://www.ag-grid.com/react-data-grid/filter-date/#date-filter-options
  const q = new FilterQueryParams()

  const queryString =
    dateTo !== undefined && dateTo !== null
      ? q.dateFilter({ field, operation: operation as DATE_FILTER_OPERATION, value: [new Date(dateFrom), new Date(dateTo)] })
      : q.dateFilter({ field, operation: operation as DATE_FILTER_OPERATION, value: new Date(dateFrom) })

  return queryString
}

function buildQueriesFromDateFilterModel(fieldName: string, dateFilterModel: DateFilterModel | ICombinedSimpleModel<DateFilterModel>) {
  let queryString = ''

  if (dateFilterModel.hasOwnProperty('operator')) {
    /*
      * interface ICombinedSimpleModel<DateFilterModel> {
        Filter type is always `'number'`
        * filterType: string

        * operator: JoinOperator // => 'AND' or 'OR'

        multiple instances of the Filter Model
        * conditions: DateFilterModel[]
      }
    */
    const { operator, conditions } = dateFilterModel as ICombinedSimpleModel<DateFilterModel>
    const joinedQueries = conditions.map((instance) => getQueryFromDateFilterInstance(fieldName, instance)).join(operator === 'AND' ? '&' : '|')
    queryString = `(${joinedQueries})`
  } else {
    /* 
      *DateFilterModel interface: {
        Note: Same type for Text, Number and Date.
        Type here is the operation => 'equals', 'not equals', etc.
        * type?: ISimpleFilterModelType | null;
        
        Filter type is always `'date'`
        * filterType?: 'date';
        
        The date value(s) associated with the filter. The type is `string` and format is always
        `YYYY-MM-DD hh:mm:ss` e.g. 2019-05-24 00:00:00. Custom filters can have no values (hence both
        are optional). Range filter has two values (from and to).
        * dateFrom: string | null;
        
        Range filter `to` date value.
        * dateTo: string | null;
        }
    */

    // here the entire model `dateFilterModel` contains just one instance

    queryString = getQueryFromDateFilterInstance(fieldName, dateFilterModel as DateFilterModel)
  }

  return queryString
}
