import { SVGProps } from 'react'
import Link from 'next/link'
import { ColDef, HeaderValueGetterParams, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core'
import capitalize from 'just-capitalize'

import { DateText, FinancialText } from '@fsg/gui-bits'
import { Calendar, Coffee, Edit3, Eye, FileTextCheck, Fire, Jollyroger, Mail, Maximize2, PhoneCall, Snowflake } from '@fsg/icons'
import { formatPhoneNumber } from '@fsg/utils'

import { CellListWithPopover } from '@app/components/GridCell/CellListWithPopover'
import {
  AccountResponse,
  ActivityResponse,
  CampaignEnums,
  CampaignResponse,
  ContactResponse,
  Division,
  EntityType,
  ExpenseResponse,
  JobFunction,
  LeadResponse,
  LeadStatus,
  ManagementPreferenceResponse,
  MarketingGroup,
  MarketingSource,
  MarketingSummaryRow,
  OpportunitiesEnums,
  OpportunityResponse,
  QualityRating,
  SingularEntityType,
  SiteOption,
  SiteResponse,
  StateResponse,
} from '@app/types'

import { divisionFormatter, formatCampaignFilterSet, normalizeENUM } from './formUtilities'
import { RouteFunction, routes } from './routes'
import { placeholder } from './usePlaceholder'

//----------------------------------------------------------------
//Component Default Configs

//this is spread on every appropriate component in this file. If you need to override any config individually, you can do it at the colDef declaration.
const dateProps = {
  hideTime: true,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
}

const financialProps = {
  decimals: 2,
}

//----------------------------------------------------------------
//References, Helper Functions, Types & Interfaces

const currentYear = new Date().getFullYear()
const prevYear = currentYear - 1

type ParseValueProps = {
  value: any
  component?: React.ReactNode
}

export const parseValue = ({ value, component }: ParseValueProps) => {
  if (value !== undefined && value != null && (typeof value === 'string' ? value.trim().length > 0 : true)) {
    if (component) {
      return component
    }
    return value
  }
  return placeholder
}

const numericSort = (valueA: number, valueB: number) => {
  return valueA - valueB
}

function noSortComparator(a: any, b: any) {
  return 0
}
interface SVGRProps {
  title?: string
  titleId?: string
}
const QUALITY_LOOKUP: {
  [key: string]: { icon: ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => JSX.Element; classes: string }
} = {
  Hot: { icon: Fire, classes: 'bg-color-error' },
  Warm: { icon: Coffee, classes: 'bg-color-warning' },
  Cold: { icon: Snowflake, classes: 'bg-color-primary-light' },
  Dead: { icon: Jollyroger, classes: 'bg-true-black' },
}
function StatusIconLink({ data, route }: { data: LeadResponse | OpportunityResponse; route: RouteFunction }) {
  const quality = QUALITY_LOOKUP[data.quality?.rating]
  if (quality === undefined) return null
  const { icon: Icon, classes } = quality
  return (
    <Link href={route(data.id)} className={`rounded-md p-4xs text-white ${classes}`}>
      <Icon className="inline" />
    </Link>
  )
}

//----------------------------------------------------------------

export abstract class GridColDef {
  static division(divisions: Division[], colProps: ColDef = {}): ColDef {
    return {
      field: 'division',
      headerName: 'Division',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: divisions.map((div: { name: string; number: string }) => div.number),
        valueFormatter: (params: ValueFormatterParams) => divisionFormatter(params, divisions),
      },
      valueFormatter: (params: ValueFormatterParams) => divisionFormatter(params, divisions),
      cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value, component: <>{divisionFormatter(params, divisions)}</> }),
      sortable: true,
      ...colProps,
    }
  }

  static qualityRating(route: RouteFunction, qualityRatingOptions: QualityRating[], colProps: ColDef = {}): ColDef {
    return {
      field: 'quality.rating',
      filter: 'agSetColumnFilter',
      maxWidth: 56,
      headerName: '',
      suppressColumnsToolPanel: true,
      cellRenderer: (params: ICellRendererParams) =>
        parseValue({ value: params.data?.quality, component: <StatusIconLink route={route} data={params.data}></StatusIconLink> }),
      filterParams: {
        values: qualityRatingOptions.map(({ rating }) => rating),
      },
      ...colProps,
    }
  }

  static entityId(colProps: ColDef = {}): ColDef {
    //this column does not render or show in the column list. it only displays on export to denote the ID of the entity for each line
    return {
      field: 'id',
      headerName: 'id',
      hide: true,
      suppressColumnsToolPanel: true,
      ...colProps,
    }
  }

  static linkToEntity(route: RouteFunction, colProps: ColDef = {}): ColDef {
    return {
      field: 'icon',
      filter: false,
      menuTabs: [],
      sortable: false,
      headerName: '',
      suppressColumnsToolPanel: true,
      maxWidth: 50, // REVIEW >> Not sure why this has to be set here, while defaultResponsiveOptions wasn't having an effect
      cellClass: '!flex !items-center',
      cellRenderer: (params: ICellRendererParams<LeadResponse | AccountResponse | OpportunityResponse | ContactResponse, string>) => (
        <Link href={route(params.data?.id)} className="text-center text-color-primary">
          <Maximize2 />
        </Link>
      ),
      pinned: 'left',
      lockPosition: 'left',
      ...colProps,
    }
  }

  static fullName(entity: SingularEntityType, colProps: ColDef = {}): ColDef {
    const route = routes[entity].base
    return {
      field: 'fullName',
      headerName: entity !== 'contact' ? `${capitalize(entity)} Contact Name` : 'Contact Name',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<LeadResponse>) =>
        parseValue({
          value: params.value,
          component: (
            <Link href={route(params.data?.id)} className="text-color-primary">
              {params.value}
            </Link>
          ),
        }),
      ...colProps,
    }
  }

  static companyName(entity: SingularEntityType, colProps: ColDef = {}): ColDef {
    const route = routes[entity].base
    return {
      field: 'companyName',
      headerName: `${capitalize(entity)} Company Name`,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<LeadResponse>) =>
        parseValue({
          value: params.value,
          component: (
            <Link href={route(params.data?.id)} className="text-color-primary">
              {params.value}
            </Link>
          ),
        }),
      ...colProps,
    }
  }

  static status(data: LeadStatus[], colProps: ColDef = {}): ColDef {
    return {
      field: 'status.status',
      headerName: 'Status',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: data.map(({ status }) => status),
      },
      cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
      ...colProps,
    }
  }

  static primaryRep(colProps: ColDef = {}): ColDef {
    return {
      field: 'primaryRep.fullName',
      headerName: 'FSG Owner',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<LeadResponse, string>) =>
        parseValue({
          value: params.value,
          component: (
            <Link href={routes.contact.basicInformation(params.data?.primaryRep?.id)} className="text-color-primary">
              {params.value}
            </Link>
          ),
        }),
      ...colProps,
    }
  }

  static source(data: MarketingSource[], colProps: ColDef = {}): ColDef {
    return {
      field: 'source.source',
      headerName: 'Source',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: data.map(({ source }) => source),
      },
      cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
      ...colProps,
    }
  }

  static estimatedValue(colProps: ColDef = {}): ColDef {
    return {
      field: 'estimatedValue',
      headerName: 'Estimated Value',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<LeadResponse | OpportunityResponse, number>) =>
        parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
      ...colProps,
    }
  }

  static nextFollowUp(colProps: ColDef = {}): ColDef {
    return {
      field: 'nextFollowUp',
      headerName: 'Follow-Up',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => {
        return params.value ? new Date(params.value).toLocaleDateString() : placeholder
      },
      cellRenderer: (params: ICellRendererParams<LeadResponse | OpportunityResponse, string>) =>
        parseValue({
          value: params.value,
          component: <DateText {...dateProps}>{params.value?.toString()}</DateText>,
        }),
      filterValueGetter: (params: ValueGetterParams<LeadResponse>) => new Date(params.data?.nextFollowUp),
      ...colProps,
    }
  }

  static updatedAt(colProps: ColDef = {}): ColDef {
    return {
      field: 'updatedAt',
      headerName: 'Last Activity',
      cellDataType: 'dateString',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => {
        return params.value ? new Date(params.value).toLocaleString() : placeholder
      },
      cellRenderer: (params: ICellRendererParams<OpportunityResponse | LeadResponse | ContactResponse | AccountResponse | SiteResponse>) =>
        parseValue({
          value: params.value,
          component: <DateText {...dateProps}>{params.value.toString()}</DateText>,
        }),
      ...colProps,
    }
  }

  static createdAt(colProps: ColDef = {}): ColDef {
    return {
      field: 'createdAt',
      headerName: 'Date Created',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => {
        return params.value ? new Date(params.value).toLocaleString() : placeholder
      },
      cellRenderer: (params: ICellRendererParams<OpportunityResponse | LeadResponse | ContactResponse | AccountResponse | SiteResponse, string>) =>
        parseValue({
          value: params.value,
          component: <DateText {...dateProps}>{params.value.toString()}</DateText>,
        }),
      filterValueGetter: (params: ValueGetterParams<LeadResponse>) => new Date(params.data?.createdAt),
      ...colProps,
    }
  }

  static customerNumber(route: RouteFunction, colProps: ColDef = {}): ColDef {
    return {
      field: 'erpId',
      headerName: 'Customer #',
      cellRenderer: (params: ICellRendererParams<AccountResponse, string>) =>
        parseValue({
          value: params.value,
          component: (
            <Link prefetch={false} href={route(params.data?.id)} className="text-color-primary">
              {params.value}
            </Link>
          ),
        }),
      filter: 'agTextColumnFilter',
      ...colProps,
    }
  }

  static entityName(entity: SingularEntityType, colProps: ColDef = {}): ColDef {
    const route = routes[entity].base
    return {
      field: 'name',
      headerName: `${capitalize(entity)} Name`,
      cellRenderer: (params: ICellRendererParams<AccountResponse | CampaignResponse, string>) =>
        parseValue({
          value: params.value,
          component: (
            <Link prefetch={false} href={route(params.data?.id)} className="text-color-primary">
              {params.value}
            </Link>
          ),
        }),
      filter: 'agTextColumnFilter',
      ...colProps,
    }
  }

  static accountManagementType(data: ManagementPreferenceResponse[], colProps: ColDef = {}): ColDef {
    return {
      field: 'accountManagementType.type',
      headerName: 'Account Management',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: data.map((m) => m.type),
      },
      cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
      ...colProps,
    }
  }

  static primaryInterest(colProps: ColDef = {}): ColDef {
    return {
      field: 'primaryInterest',
      headerName: 'Primary Interest',
      filter: true,
      cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
      ...colProps,
    }
  }

  static marketingGroup(data: MarketingGroup[], colProps: ColDef = {}): ColDef {
    return {
      field: 'marketingGroup.description',
      headerName: 'Marketing Group',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: data.map((m) => m.description),
      },
      cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
      ...colProps,
    }
  }

  static accountPortfolioManager(colProps: ColDef = {}): ColDef {
    return {
      field: 'accountPortfolioManager.fullName',
      headerName: 'Account Manager',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<OpportunityResponse | AccountResponse>) =>
        parseValue({
          value: params.value,
          component: (
            <Link className="text-color-primary" href={routes.contact.base(params.data?.accountPortfolioManager?.id)} prefetch={false}>
              {params.value}
            </Link>
          ),
        }),
      ...colProps,
    }
  }

  static businessDevelopmentManager(colProps: ColDef = {}): ColDef {
    return {
      field: 'businessDevelopmentManager.fullName',
      headerName: 'Current Assignee',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<OpportunityResponse | AccountResponse>) =>
        parseValue({
          value: params.value,
          component: (
            <Link className="text-color-primary" href={routes.contact.base(params.data?.businessDevelopmentManager?.id)} prefetch={false}>
              {params.value}
            </Link>
          ),
        }),
      ...colProps,
    }
  }

  static totalOpportunities(colProps: ColDef = {}): ColDef {
    return {
      field: 'totalOpportunities',
      headerName: 'Opportunities',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
      ...colProps,
    }
  }

  static totalEstimatedRevenue(colProps: ColDef = {}): ColDef {
    return {
      field: 'totalEstimatedRevenue',
      headerName: 'Opportunity $',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<AccountResponse, number>) =>
        parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
      ...colProps,
    }
  }

  static previousYearSales(colProps: ColDef = {}): ColDef {
    return {
      field: 'previousYearSalesToDate',
      headerName: `${prevYear} Sales`,
      filter: 'agNumberColumnFilter',
      valueFormatter: (params) => {
        return params.value ? parseFloat(params.value).toFixed(2).toString() : placeholder
      },
      cellRenderer: (params: ICellRendererParams<AccountResponse, number>) =>
        parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
      ...colProps,
    }
  }

  static currentYearSales(colProps: ColDef = {}): ColDef {
    return {
      field: 'currentYearSalesToDate',
      headerName: `${currentYear} Sales YTD`,
      filter: 'agNumberColumnFilter',
      valueFormatter: (params) => {
        return params.value ? parseFloat(params.value).toFixed(2).toString() : placeholder
      },
      cellRenderer: (params: ICellRendererParams<AccountResponse, number>) =>
        parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
      ...colProps,
    }
  }

  static campaign(colProps: ColDef = {}): ColDef {
    return {
      field: 'campaign.name',
      headerName: 'Campaign',
      filter: 'agTextColumnFilter',
      minWidth: 200,
      cellRenderer: (params: ICellRendererParams<LeadResponse | OpportunityResponse, CampaignResponse>) =>
        parseValue({
          value: params.value,
          component: (
            <Link
              key={params.data.campaign?.id}
              className="text-color-primary"
              href={routes.campaign.base(params.data.campaign?.id)}
              prefetch={false}
            >
              {params.data.campaign?.name}
            </Link>
          ),
        }),
      ...colProps,
    }
  }

  static Account = {
    status(colProps: ColDef = {}): ColDef {
      return {
        field: 'accountStatus',
        valueFormatter: (params: ValueFormatterParams<AccountResponse>) => (params.value ? 'Active' : 'Inactive'),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: ValueFormatterParams<AccountResponse>) => (params.value ? 'Active' : 'Inactive'),
        },
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value, component: <>{params.value ? 'Active' : 'Inactive'}</> }),
        ...colProps,
      }
    },

    Site: {
      id(colProps: ColDef = {}): ColDef {
        return {
          field: 'erpId',
          headerName: 'FSG Site ID',
          filter: 'agTextColumnFilter',
          cellRenderer: (params: ICellRendererParams<SiteResponse>) =>
            parseValue({
              value: params.value,
              component: (
                <Link prefetch={false} href={`/sites/${params.data?.id}/general`} className="text-color-primary">
                  {params.value}
                </Link>
              ),
            }),
          ...colProps,
        }
      },

      storeNumber(colProps: ColDef = {}): ColDef {
        return {
          field: 'customerSiteId',
          headerName: 'Customer Store Number',
          filter: 'agTextColumnFilter',
          cellRenderer: (params: ICellRendererParams<SiteResponse>) =>
            parseValue({
              value: params.value,
              component: (
                <Link prefetch={false} href={`/sites/${params.data?.id}/general`} className="text-color-primary">
                  {params.value}
                </Link>
              ),
            }),
          ...colProps,
        }
      },

      select(
        { fields, append, remove }: { fields: any[]; append: (site?: SiteOption) => void; remove: (index?: number) => void },
        colProps: ColDef = {},
      ): ColDef {
        return {
          field: 'select',
          headerName: '',
          maxWidth: 50,
          suppressMovable: true,
          suppressColumnsToolPanel: true,
          sortable: false,
          checkboxSelection: true,
          suppressHeaderMenuButton: true,
          // valueGetter: (params: ValueGetterParams<SiteResponse>) => {
          //   const checkedField = fields.find((site: any) => site.value === params.data?.id)
          //   return checkedField ? 1 : 0
          // },
          // cellRenderer: (params: ICellRendererParams<SiteResponse>) => {
          //   const checkedField = fields.find((site: any) => site.value === params.data.id)
          //   const isChecked = checkedField !== undefined
          //   function handleOnChange(site: SiteResponse) {
          //     if (!isChecked) {
          //       append({
          //         label: site.name,
          //         value: site.id,
          //         site: site,
          //       })
          //     } else {
          //       remove(fields.indexOf(checkedField))
          //     }
          //   }

          //   return (
          //     <input
          //       className="!inline"
          //       type="checkbox"
          //       checked={isChecked}
          //       onChange={() => {
          //         handleOnChange(params.data)
          //       }}
          //     />
          //   )
          // },

          ...colProps,
        }
      },

      terms(colProps: ColDef = {}): ColDef {
        return {
          field: 'netTerms',
          headerName: 'Terms',
          ...colProps,
        }
      },
    },
  }

  static Opportunity = {
    probability(colProps: ColDef = {}): ColDef {
      return {
        field: 'probability',
        headerName: 'Probability',
        filter: true,
        hide: true,
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
        ...colProps,
      }
    },

    description(colProps: ColDef = {}): ColDef {
      return {
        field: 'opportunityDescription',
        headerName: 'Opportunity Description',
        filter: true,
        hide: true,
        resizable: true,
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
        ...colProps,
      }
    },
    estCloseDate(colProps: ColDef = {}): ColDef {
      return {
        field: 'estCloseDate',
        headerName: 'Estimated Close Date',
        valueFormatter: (params) => {
          return params.value ? new Date(params.value).toLocaleDateString() : placeholder
        },
        cellRenderer: (params: ICellRendererParams<OpportunityResponse>) =>
          parseValue({
            value: params.value,
            component: <DateText {...dateProps}>{params.value?.toString()}</DateText>,
          }),
        filter: 'agDateColumnFilter',
        ...colProps,
      }
    },
    bidDueDate(colProps: ColDef = {}): ColDef {
      return {
        field: 'bidDueDate',
        headerName: 'Due Date',
        cellDataType: 'dateString',
        filter: 'agDateColumnFilter',
        valueFormatter: (params) => {
          return params.value ? new Date(params.value).toLocaleDateString() : placeholder
        },
        cellRenderer: (params: ICellRendererParams<OpportunityResponse>) =>
          parseValue({
            value: params.value,
            component: <DateText {...dateProps}>{params.value?.toString()}</DateText>,
          }),
        ...colProps,
      }
    },

    accountName(colProps: ColDef = {}): ColDef {
      return {
        field: 'account.name',
        headerName: 'Customer',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams<OpportunityResponse>) =>
          parseValue({
            value: params.value,
            component: (
              <Link prefetch={false} href={`/accounts/${params.data?.account.id}/general/basic-information`} className="text-color-primary">
                {params.value}
              </Link>
            ),
          }),
        ...colProps,
      }
    },

    budget(colProps: ColDef = {}): ColDef {
      return {
        field: 'budget',
        headerName: 'Budget',
        cellRenderer: (params: ICellRendererParams<OpportunityResponse>) =>
          parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value.toString()}</FinancialText> }),
        filter: 'agNumberColumnFilter',
        ...colProps,
      }
    },

    primaryStakeholder(colProps: ColDef = {}): ColDef {
      return {
        field: 'primaryStakeholder.fullName',
        headerName: 'Primary Customer Stakeholder',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams<OpportunityResponse>) =>
          parseValue({
            value: params.value,
            component: (
              <Link prefetch={false} href={`/contacts/${params.data?.primaryStakeholder?.id}/basic-information`} className="text-color-primary">
                {params.value}
              </Link>
            ),
          }),
        ...colProps,
      }
    },

    team(colProps: ColDef = {}): ColDef {
      return {
        field: 'estimators.fullName',
        headerName: 'Team',
        filter: 'agTextColumnFilter',
        valueGetter: (params: ValueGetterParams<OpportunityResponse>) => {
          return params.data?.estimators
        },
        valueFormatter: (params) => {
          return params.data?.estimators.length > 0 ? params.value?.map((contact: ContactResponse) => contact.fullName) : placeholder
        },
        minWidth: 200,
        cellRenderer: (params: ICellRendererParams<OpportunityResponse, OpportunityResponse['estimators']>) =>
          parseValue({
            value: params.value,
            component: (
              <CellListWithPopover>
                {params.value?.map((contact) => (
                  <Link key={contact.id} className="text-color-primary" href={routes.contact.basicInformation(contact.id)} prefetch={false}>
                    {contact.fullName}
                  </Link>
                ))}
              </CellListWithPopover>
            ),
          }),
        ...colProps,
      }
    },

    stage(ENUMS: OpportunitiesEnums, colProps: ColDef = {}): ColDef {
      return {
        field: 'stage',
        headerName: 'Stage',
        cellRenderer: (params: ICellRendererParams<OpportunityResponse>) =>
          parseValue({ value: params.value, component: <>{normalizeENUM(params.value ?? '')}</> }),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(ENUMS.opportunityStages),
          cellRenderer: (params: any) => {
            return normalizeENUM(params.value)
          },
        },
        ...colProps,
      }
    },
  }

  static Campaign = {
    type(enums: CampaignEnums, colProps: ColDef = {}): ColDef {
      return {
        field: 'type',
        headerName: 'Type',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(enums.campaignType),
          cellRenderer: (params: any) => {
            return formatCampaignFilterSet(params.value)
          },
        },
        cellRenderer: (params: ICellRendererParams<CampaignResponse, string>) =>
          parseValue({ value: params.value, component: <>{formatCampaignFilterSet(params.value ?? '')}</> }),
        ...colProps,
      }
    },
    focus(enums: CampaignEnums, colProps: ColDef = {}): ColDef {
      return {
        field: 'focus',
        headerName: 'Focus',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(enums.campaignFocus),
          cellRenderer: (params: any) => {
            return formatCampaignFilterSet(params.value)
          },
        },
        cellRenderer: (params: ICellRendererParams<CampaignResponse, string>) =>
          parseValue({ value: params.value, component: <>{formatCampaignFilterSet(params.value ?? '')}</> }),
        ...colProps,
      }
    },
    plannedStart(colProps: ColDef = {}): ColDef {
      return {
        field: 'plannedStart',
        headerName: 'Planned Start',
        filter: 'agDateColumnFilter',
        valueFormatter: (params) => {
          return params.value ? new Date(params.value).toLocaleDateString() : placeholder
        },
        cellRenderer: (params: ICellRendererParams<CampaignResponse, string>) =>
          parseValue({ value: params.value, component: <DateText {...dateProps}>{new Date(params.value).toString()}</DateText> }),
        ...colProps,
      }
    },

    plannedEnd(colProps: ColDef = {}): ColDef {
      return {
        field: 'plannedEnd',
        headerName: 'Planned End',
        filter: 'agDateColumnFilter',
        valueFormatter: (params) => {
          return params.value ? new Date(params.value).toLocaleDateString() : placeholder
        },
        cellRenderer: (params: ICellRendererParams<CampaignResponse, string>) =>
          parseValue({ value: params.value, component: <DateText {...dateProps}>{new Date(params.value).toString()}</DateText> }),
        ...colProps,
      }
    },
    actualCost(colProps: ColDef = {}): ColDef {
      return {
        field: 'actualCost',
        headerName: 'Actual Cost',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params) => {
          return params.value ? parseFloat(params.value).toFixed(2).toString() : placeholder
        },
        cellRenderer: (params: ICellRendererParams<CampaignResponse>) =>
          parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
        ...colProps,
      }
    },
    actualRevenue(colProps: ColDef = {}): ColDef {
      return {
        field: 'actualRevenue',
        headerName: 'Actual Revenue',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params) => {
          return params.value ? parseFloat(params.value).toFixed(2).toString() : placeholder
        },
        cellRenderer: (params: ICellRendererParams<CampaignResponse>) =>
          parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
        ...colProps,
      }
    },
    actualROI(colProps: ColDef = {}): ColDef {
      return {
        field: 'actualRevenueROI',
        headerName: 'ROI on Actual Revenue',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params) => {
          return params.value ? parseFloat(params.value).toFixed(2).toString() : placeholder
        },
        cellRenderer: (params: ICellRendererParams<CampaignResponse>) =>
          parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
        ...colProps,
      }
    },
    grossROI(colProps: ColDef = {}): ColDef {
      return {
        field: 'grossProfitROI',
        headerName: 'ROI on Actual Gross Profit',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params) => {
          return params.value ? parseFloat(params.value).toFixed(2).toString() : placeholder
        },
        cellRenderer: (params: ICellRendererParams<CampaignResponse>) =>
          parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
        ...colProps,
      }
    },
    totalLeads(colProps: ColDef = {}): ColDef {
      return {
        field: 'totalLeads',
        headerName: 'Lead Count',
        filter: 'agNumberColumnFilter',
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
        ...colProps,
      }
    },

    Expense: {
      link(route: RouteFunction, campaignId: string, colProps: ColDef = {}): ColDef {
        return {
          field: 'id',
          filter: false,
          menuTabs: [],
          sortable: false,
          headerName: '',
          suppressColumnsToolPanel: true,
          maxWidth: 50, // REVIEW >> Not sure why this has to be set here, while defaultResponsiveOptions wasn't having an effect
          cellClass: '!flex !items-center',
          cellRenderer: (params: ICellRendererParams<ExpenseResponse, string>) => (
            <Link href={route(campaignId, params.value)} className="text-center text-color-primary">
              <Maximize2 />
            </Link>
          ),
          ...colProps,
        }
      },
      name(route: RouteFunction, campaignId: string, colProps: ColDef = {}): ColDef {
        return {
          field: 'name',
          headerName: 'Expense #',
          filter: 'agNumberColumnFilter',
          cellRenderer: (params: ICellRendererParams<ExpenseResponse>) =>
            parseValue({
              value: params.value,
              component: (
                <Link href={route(campaignId, params.data?.id)} className="text-color-primary">
                  {params.value}
                </Link>
              ),
            }),
          ...colProps,
        }
      },

      paymentDate(colProps: ColDef = {}): ColDef {
        return {
          field: 'paymentDate',
          headerName: 'Payment Date',
          filter: 'agDateColumnFilter',
          cellRenderer: (params: ICellRendererParams<ExpenseResponse, string>) =>
            parseValue({ value: params.value, component: <DateText {...dateProps}>{new Date(params.value).toString()}</DateText> }),
          ...colProps,
        }
      },
      payee(colProps: ColDef = {}): ColDef {
        return {
          field: 'payee',
          headerName: 'Payee',
          filter: 'agTextColumnFilter',
          cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
          ...colProps,
        }
      },
      description(colProps: ColDef = {}): ColDef {
        return {
          field: 'description',
          headerName: 'Description',
          filter: 'agTextColumnFilter',
          cellRenderer: (params: ICellRendererParams<ExpenseResponse, string>) => parseValue({ value: params.value }),
          ...colProps,
        }
      },
      costCode(colProps: ColDef = {}): ColDef {
        return {
          field: 'costCode.description',
          headerName: 'Cost Code',
          filter: 'agSetColumnFilter',
          cellRenderer: (params: ICellRendererParams<ExpenseResponse, string>) =>
            parseValue({
              value: params.data?.costCode?.code,
              component: <>{`${params.data?.costCode?.code} - ${params.data?.costCode?.description}`}</>,
            }),
          ...colProps,
        }
      },
      owner(colProps: ColDef = {}): ColDef {
        return {
          field: 'owner',
          headerName: 'Owner',
          filter: 'agTextColumnFilter',
          cellRenderer: (params: ICellRendererParams<ExpenseResponse, ContactResponse>) =>
            parseValue({
              value: params.value,
              component: (
                <Link href={routes.contact.basicInformation(params.value.id)} className="text-color-primary">
                  {params.value.fullName}
                </Link>
              ),
            }),
          ...colProps,
        }
      },
      total(colProps: ColDef = {}): ColDef {
        return {
          field: 'total',
          headerName: 'Total',
          filter: 'agNumberColumnFilter',
          cellRenderer: (params: ICellRendererParams<ExpenseResponse, number>) =>
            parseValue({ value: params.value, component: <FinancialText {...financialProps}>{params.value?.toString()}</FinancialText> }),
          ...colProps,
        }
      },
    },
  }

  static Activity = {
    type(colProps: ColDef = {}): ColDef {
      return {
        field: 'activityType',
        headerName: 'Activity Type',
        valueGetter: (params) => normalizeENUM(params.data?.activityType),
        cellRenderer: (params: ICellRendererParams) =>
          parseValue({ value: params.value, component: <>{normalizeENUM(params.data?.activityType ?? '')}</> }),
        ...colProps,
      }
    },
    typeIcon(colProps: ColDef = {}): ColDef {
      const iconLookup = [
        { activityType: 'PHONE_CALL', icon: () => <PhoneCall /> },
        { activityType: 'EMAIL', icon: () => <Mail /> },
        { activityType: 'MEETING', icon: () => <Calendar /> },
        // { activityType: 'Task Creation', icon: () => <CheckSquare /> },
        // { activityType: 'Task Completion', icon: () => <CheckSquare /> },
        { activityType: 'STATUS_CHANGE', icon: () => <FileTextCheck /> },
        { activityType: 'VIEW_ONLY', icon: () => <Eye /> },
        { activityType: 'EDIT', icon: () => <Edit3 /> },
      ]

      return {
        field: 'icon',
        filter: false,
        menuTabs: [],
        sortable: false,
        headerName: '',
        suppressColumnsToolPanel: true,
        maxWidth: 50, // REVIEW >> Not sure why this has to be set here, while defaultResponsiveOptions wasn't having an effect
        cellClass: '!flex !items-center',
        cellRenderer: (params: ICellRendererParams) => {
          const matchedIcon = iconLookup.find((obj) => obj.activityType === params.data?.activityType)
          return matchedIcon ? matchedIcon.icon() : null
        },
        ...colProps,
      }
    },
    by(colProps: ColDef = {}): ColDef {
      return {
        field: 'user.email',
        headerName: 'Activity By',
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
        ...colProps,
      }
    },
    date(colProps: ColDef = {}): ColDef {
      return {
        headerName: 'Date',
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          const date = new Date(params.data?.recordedAt)
          return date.setHours(0, 0, 0, 0)
        },
        sort: 'desc',
        cellRenderer: (params: ICellRendererParams<ActivityResponse, string>) =>
          parseValue({
            value: params.data?.recordedAt,
            component: (
              <DateText {...dateProps} hideTime={false}>
                {params.data?.recordedAt?.toString()}
              </DateText>
            ),
          }),
        ...colProps,
      }
    },
    details(colProps: ColDef = {}): ColDef {
      return {
        field: 'notes.content',
        headerName: 'Details',
        valueFormatter: (params) =>
          params.data?.activityType === 'VIEW_ONLY'
            ? `Daily views: ${params.data?.notes.length}`
            : params.data?.notes.length > 0
            ? params.data?.notes[0].content
            : placeholder,
        ...colProps,
      }
    },
  }

  static Contact = {
    companyName(colProps: ColDef = {}): ColDef {
      return {
        field: 'currentRole.account.name',
        headerName: 'Company Name',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams<ContactResponse, string>) =>
          parseValue({
            value: params.data?.currentRole?.accountName,
            component: (
              <Link
                prefetch={false}
                href={`accounts/${params.data?.currentRole?.accountId}/general/basic-information`}
                className="text-color-primary"
              >
                {params.data?.currentRole?.accountName}
              </Link>
            ),
          }),
        ...colProps,
      }
    },
    title(colProps: ColDef = {}): ColDef {
      return {
        field: 'currentRole.title',
        headerName: 'Title',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
        ...colProps,
      }
    },
    jobFunctions(colProps: ColDef = {}): ColDef {
      return {
        field: 'currentRole.jobFunctions',
        headerName: 'Job Functions',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams<ContactResponse, JobFunction[]>) => {
          return params.value.length > 0 ? params.value.map((j) => j.function).join(', ') : placeholder
        },
        ...colProps,
      }
    },
  }

  static salesType(ENUMS: OpportunitiesEnums, colProps: ColDef = {}): ColDef {
    return {
      field: 'salesType',
      headerName: 'Sales Type',
      filter: 'agSetColumnFilter',
      cellRenderer: (params: ICellRendererParams<OpportunityResponse>) => {
        return params.data?.salesType ? normalizeENUM(params.data?.salesType) : placeholder
      },
      filterParams: {
        values: Object.values(ENUMS.salesTypes),
        cellRenderer: (params: any) => {
          return normalizeENUM(params.value)
        },
      },
      ...colProps,
    }
  }

  static PrimaryPhone = {
    number(colProps: ColDef = {}): ColDef {
      return {
        field: 'primaryPhone.number',
        headerName: 'Phone Number',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          let phoneNumber = params.data?.primaryPhone?.number
          if (phoneNumber?.length > 0) {
            const number = formatPhoneNumber(phoneNumber)
            return number?.formatted ?? ''
          }
          return ''
        },
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
        ...colProps,
      }
    },
  }

  static PrimaryEmail = {
    address(colProps: ColDef = {}): ColDef {
      return {
        field: 'primaryEmail.address',
        headerName: 'Email',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.value }),
        ...colProps,
      }
    },
  }

  static PrimaryAddress = {
    city(colProps: ColDef = {}): ColDef {
      return {
        field: 'primaryAddress.city',
        headerName: 'City',
        // NOTE: accounts should always have at least one address because it is a required field on creation
        cellRenderer: (params: ICellRendererParams<AccountResponse | LeadResponse | ContactResponse, string>) =>
          parseValue({ value: params.data?.primaryAddress?.city }),
        valueFormatter: (params) => (params.data?.primaryAddress ? params.data?.primaryAddress?.city : placeholder),
        filter: 'agTextColumnFilter',
        ...colProps,
      }
    },
    state(states: StateResponse[], provinces?: StateResponse[], colProps: ColDef = {}): ColDef {
      if (provinces === undefined) {
        provinces = []
      }
      const stateValues = [...states.map((state) => state.name), ...provinces.map((state) => state.name)]
      return {
        field: 'primaryAddress.state.name',
        headerName: 'State',
        cellRenderer: (params: ICellRendererParams<AccountResponse | LeadResponse | ContactResponse, string>) =>
          parseValue({ value: params.data?.primaryAddress?.state }),
        valueFormatter: (params) => (params.data?.primaryAddress ? params.data?.primaryAddress.state : placeholder),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: stateValues,
          comparator: noSortComparator,
        },
        ...colProps,
      }
    },
    street(colProps: ColDef = {}): ColDef {
      return {
        field: 'address',
        headerName: 'Street Address',
        filter: 'agTextColumnFilter',
        valueFormatter: (params) => {
          return params.data?.primaryAddress ? params.data?.primaryAddress.line1 : placeholder
        },
        cellRenderer: (params: ICellRendererParams<AccountResponse | LeadResponse | ContactResponse, string>) =>
          parseValue({ value: params.data?.primaryAddress?.line1 }),
        ...colProps,
      }
    },
  }

  static BillingAddress = {
    city(colProps: ColDef = {}): ColDef {
      return {
        field: 'billingAddress.city',
        headerName: 'Billing City',
        // NOTE: accounts should always have at least one address because it is a required field on creation
        cellRenderer: (params: ICellRendererParams<SiteResponse, string>) => parseValue({ value: params.data?.billingAddress?.city }),
        valueFormatter: (params) => (params.data?.billingAddress ? params.data?.billingAddress.city : placeholder),
        filter: 'agTextColumnFilter',
        ...colProps,
      }
    },
    state(states: StateResponse[], provinces?: StateResponse[], colProps: ColDef = {}): ColDef {
      if (provinces === undefined) {
        provinces = []
      }
      const stateValues = [...states.map((state) => state.name), ...provinces.map((state) => state.name)]
      return {
        field: 'billingAddress.state.name',
        headerName: 'Billing State/Province/Territory',
        cellRenderer: (params: ICellRendererParams<SiteResponse, string>) => parseValue({ value: params.data?.billingAddress?.state }),
        valueFormatter: (params) => (params.data?.billingAddress ? params.data?.billingAddress.state : placeholder),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: stateValues,
          comparator: noSortComparator,
        },
        ...colProps,
      }
    },
    street(colProps: ColDef = {}): ColDef {
      return {
        field: 'billingAddress',
        headerName: 'Billing Street Address',
        filter: 'agTextColumnFilter',
        valueFormatter: (params) => {
          return params.data?.billingAddress ? params.data?.billingAddress.line1 : placeholder
        },
        cellRenderer: (params: ICellRendererParams<SiteResponse, string>) => parseValue({ value: params.data?.billingAddress?.line1 }),
        ...colProps,
      }
    },
  }

  static MarketingSummary = {
    linkToEntity(colProps: ColDef = {}): ColDef {
      return {
        field: 'icon',
        filter: false,
        menuTabs: [],
        sortable: false,
        headerName: '',
        suppressColumnsToolPanel: true,
        maxWidth: 50, // REVIEW >> Not sure why this has to be set here, while defaultResponsiveOptions wasn't having an effect
        cellClass: '!flex !items-center',
        cellRenderer: (params: ICellRendererParams<LeadResponse | OpportunityResponse, string>) => {
          const route = (params.data as any).type === 'lead' ? routes.lead.base : routes.opportunity.base

          return (
            <Link href={route(params.data?.id)} className="text-center text-color-primary">
              <Maximize2 />
            </Link>
          )
        },
        ...colProps,
      }
    },
    type(colProps: ColDef = {}): ColDef {
      return {
        field: 'type',
        headerName: 'Type',
        filter: 'agSetColumnFilter',
        filterParams: {
          cellRenderer: (params: any) => {
            return capitalize(params.value)
          },
        },
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          return capitalize(params.data.type)
        },
        ...colProps,
      }
    },
    entityName(colProps: ColDef = {}): ColDef {
      return {
        field: 'name',
        headerName: 'Lead/Opportunity Name',
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          const type = (params.data as any).type
          const entity = params.data
          const route = type === 'lead' ? routes.lead.base : routes.opportunity.base

          return (
            <Link prefetch={false} href={route(params.data?.id)} className="text-color-primary">
              {entity.name}
            </Link>
          )
        },
        filter: 'agTextColumnFilter',
        ...colProps,
      }
    },
    customer(colProps: ColDef = {}): ColDef {
      return {
        field: 'customer',
        headerName: 'Customer',
        valueFormatter: (params: ValueFormatterParams<MarketingSummaryRow>) => {
          if (typeof params.value === 'string') {
            return params.value
          } else {
            return (params.value as AccountResponse).name
          }
        },
        valueGetter: (params) => {
          const type = (params.data as any).type
          const entity = params.data
          return type === 'lead' ? (entity.customer as string) : (entity.customer as AccountResponse).name
        },
        cellRenderer: (params: ICellRendererParams<MarketingSummaryRow, string>) => {
          const type = (params.data as any).type
          const entity = params.data
          const value = type === 'lead' ? (entity.customer as string) : (entity.customer as AccountResponse).name

          if (type === 'opportunity') {
            return (
              <Link prefetch={false} href={routes.account.base(entity?.id)} className="text-color-primary">
                {value}
              </Link>
            )
          } else {
            return value
          }
        },
        filter: 'agTextColumnFilter',
        ...colProps,
      }
    },
    fsgOwner(colProps: ColDef = {}): ColDef {
      return {
        field: 'fsgOwner',
        headerName: 'FSG Owner',
        filter: 'agSetColumnFilter',
        valueFormatter: (params: ValueFormatterParams<MarketingSummaryRow>) => {
          return params.value?.fullName
        },
        cellRenderer: (params: ICellRendererParams<MarketingSummaryRow, string>) => {
          const type = (params.data as any).type
          const entity = params.data
          const value = entity.fsgOwner?.fullName

          return parseValue({
            value,
            component: (
              <Link prefetch={false} href={routes.contact.base(params.data?.id)} className="text-color-primary">
                {value}
              </Link>
            ),
          })
        },
      }
    },
    source(data: MarketingSource[], colProps: ColDef = {}): ColDef {
      return {
        field: 'source',
        headerName: 'Source',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: data.map(({ source }) => source),
        },
        cellRenderer: (params: ICellRendererParams) => parseValue({ value: params.data.source }),
        ...colProps,
      }
    },
    campaign(colProps: ColDef = {}): ColDef {
      return {
        field: 'campaign',
        headerName: 'Campaign',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          return parseValue({ value: params.data.campaign })
        },
        ...colProps,
      }
    },
    status(filters: any[], colProps: ColDef = {}): ColDef {
      return {
        field: 'status',
        headerName: 'Status',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filters,
          cellRenderer: (params: any) => {
            return normalizeENUM(params.value)
          },
        },
        cellRenderer: (params: ICellRendererParams<MarketingSummaryRow>) => {
          const type = (params.data as any).type
          const entity = params.data
          const value = type === 'lead' ? entity.status : normalizeENUM(entity.status)

          return parseValue({ value, component: value })
        },
        ...colProps,
      }
    },
    estimatedRevenue(colProps: ColDef = {}): ColDef {
      return {
        field: 'estimatedRevenue',
        headerName: 'Estimated Revenue',
        filter: 'agNumberColumnFilter',
        cellRenderer: (params: ICellRendererParams<any, number>) => {
          const value = params.data.estimatedRevenue
          return parseValue({ value, component: <FinancialText {...financialProps}>{value?.toString()}</FinancialText> })
        },
        valueFormatter: (params: ValueFormatterParams<MarketingSummaryRow>) => {
          return params.value ? parseFloat(params.value).toFixed(2).toString() : placeholder
        },
        comparator: numericSort,
        ...colProps,
      }
    },
    probabilityDollars(colProps: ColDef = {}): ColDef {
      return {
        field: 'probabilityDollars',
        headerName: 'Probable $',
        filter: false,
        cellRenderer: (params: ICellRendererParams<any, number>) => {
          const entity = params.data as any
          const type = entity.type
          let value = entity.probabilityDollars
          if (type === 'opportunity') {
            return parseValue({ value, component: <FinancialText {...financialProps}>{value?.toString()}</FinancialText> })
          }
          return 'N/A'
        },
        valueFormatter: (params: ValueFormatterParams<MarketingSummaryRow>) => {
          return params.value ? parseFloat(params.value).toFixed(2).toString() : placeholder
        },
        comparator: numericSort,
        ...colProps,
      }
    },
    quoted(colProps: ColDef = {}): ColDef {
      return {
        field: 'quotedValue',
        headerName: 'Quoted $',
        filter: 'agNumberColumnFilter',
        cellRenderer: (params: ICellRendererParams<any, number>) => {
          const value = params.data.quotedValue
          return parseValue({ value, component: <FinancialText {...financialProps}>{value?.toString()}</FinancialText> })
        },
        comparator: numericSort,
        ...colProps,
      }
    },
  }
}
