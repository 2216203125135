import { ColDef } from '@ag-grid-community/core'
import pascalCase from 'just-pascal-case'
import { parsePhoneNumber } from 'libphonenumber-js'

import {
  AccountResponse,
  ADDRESS_LABEL,
  AddressFormData,
  AddressRequest,
  AddressResponse,
  ContactResponse,
  ContactRoleRequest,
  ContactRoleResponse,
  CostCode,
  CountryType,
  Division,
  EmailRequest,
  EmailResponse,
  Industries,
  JobFunction,
  LeadStatus,
  Option,
  PaymentMethod,
  PhoneFormData,
  PhoneRequest,
  PhoneResponse,
  SelectOption,
  SiteTypes,
  StateType,
} from '@app/types'
import { placeholder } from './usePlaceholder'

export type phoneTypes = 'cellular' | 'landline'

//REVIEW -> @jason can show @chris a way to possibly do this cleaner/quicker
export function formatCamelCaseLabel(value: string) {
  const pascal = pascalCase(value)
  const pascalPattern = /([a-z])([A-Z])/g
  const result = pascal.replace(pascalPattern, '$1 $2')
  return result
}

export function formatCostCodeOption(costCode: CostCode) {
  return {
    label: costCode.description,
    value: costCode.id,
  }
}

export function filterBlankValues(data: any, key: string) {
  return data.filter((obj: any) => {
    return obj && obj[key] !== ''
  })
}

type HandleRemoveProps = {
  entity: 'address' | 'phone number'
  index: number
  primaryIndex: number
  numberRequired: number
  primaryField: string
  name: string
  array: any[]
  setError: any
  clearError: any
  removeItem: any
  setField: any
  resetFields: any
  setValue: any
}

export function handlePlural(input: string) {
  let output

  switch (input) {
    case 'address':
      output = 'addresses'
      break
    case 'phone number':
      output = 'phone numbers'
      break
    case 'lead':
      output = 'leads'
      break
    case 'opportunity':
      output = 'opportunities'
      break
    case 'site':
      output = 'sites'
      break
    case 'contact':
      output = 'contacts'
      break
    case 'account':
      output = 'accounts'
      break
    default:
      output = input
      break
  }
  return output
}

export function handleRemove({
  entity,
  index,
  primaryIndex,
  numberRequired,
  primaryField,
  name,
  array,
  setError,
  clearError,
  removeItem,
  setField,
  resetFields,
  setValue,
}: HandleRemoveProps): void {
  const pluralEntity = handlePlural(entity)

  if (numberRequired && array.length - 1 < numberRequired) {
    setError(name, {
      type: 'manual',
      message:
        numberRequired <= 1 ? `You must provide at least ${numberRequired} ${entity}` : `You must provide at least ${numberRequired} ${pluralEntity}`,
    })
    resetFields(index as number)
  } else {
    clearError(name)
    setField(array, index as number, primaryIndex, primaryField, setValue)
    removeItem(index)
  }
}

export function formatStringOption(option: string) {
  return { value: option, label: option }
}

export function normalizeENUM(input: string) {
  const words = input.split('_')
  const pascalCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  })
  const pascalCaseString = pascalCaseWords.join(' ')
  return pascalCaseString
}

export function formatSingleENUM(input: string) {
  return { value: input, label: normalizeENUM(input) }
}

export function formatSingleCampaignENUM(input: string) {
  if (input === 'CPC') {
    return { value: input, label: input }
  } else {
    return { value: input, label: normalizeENUM(input) }
  }
}

export function formatCampaignFilterSet(input: string) {
  if (input === 'CPC') {
    return 'CPC'
  } else {
    return normalizeENUM(input)
  }
}

export function formatENUMOptions(object: any) {
  return Object.keys(object).map((key) => ({
    value: object[key],
    label: normalizeENUM(object[key]),
  }))
}

export function formatCampaignENUMS(object: any) {
  return Object.keys(object).map((key) => {
    if (object[key] === 'CPC') {
      return {
        value: 'CPC',
        label: 'CPC',
      }
    } else {
      return {
        value: object[key],
        label: normalizeENUM(object[key]),
      }
    }
  })
}

export function formatKelvinENUMOptions(object: any) {
  function normalizeKelvinOption(input: string) {
    const words = input.split('_')
    const pascalCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    const option = pascalCaseWords[1]?.split('K').length ? `${pascalCaseWords[1]?.split('K')[0]} K` : ''
    return option
  }

  const output = Object.keys(object).map((key) => {
    if (object[key] !== 'NONE') {
      return {
        value: object[key],
        label: normalizeKelvinOption(object[key]),
      }
    } else {
      return {
        value: 'NONE',
        label: 'None',
      }
    }
  })
  return output
}

export function formatSiteTypeOption(type: SiteTypes) {
  return {
    value: type.id,
    label: type.type,
    description: type.shortDescription,
    longDescription: type.longDescription,
  }
}

export function isValidUUID(uuid: string) {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return pattern.test(uuid)
}

export function siteTypeFilterOptions(option: any, input: string): boolean {
  return option.label.toLowerCase().includes(input.toLowerCase()) || option.data.longDescription.toLowerCase().includes(input.toLowerCase())
}

export function formatIndustryOption(industry: Industries) {
  return { value: industry.title, label: `(${industry.code}) ${industry.title}`, description: industry.description }
}

export function getIndustryRequest(industries: { value: string; label: string; description: string }[]) {
  return industries
    ? industries?.map((industry) => {
        return { title: industry.value }
      })
    : []
}

export function industryFilterOptions(option: any, input: string): boolean {
  return option.label.toLowerCase().includes(input.toLowerCase()) || option.data.description.toLowerCase().includes(input.toLowerCase())
}

export function formatJobFunctionOption(functionOption: JobFunction) {
  return {
    value: functionOption.function,
    label: functionOption.function,
    subType: functionOption.subType,
    type: functionOption.type,
    function: functionOption.function,
  }
}

export function findSelectedCountryValue(options: any[], value: string) {
  const opt = options.find((o) => o.code === value)
  return opt ? opt.value : ''
}

export function sortLeadStatuses(obj: LeadStatus[]) {
  const order: { [key: string]: number } = {
    'New': 1,
    'Contacted': 2,
    'Qualified - Converted': 3,
    'Not Qualified - No Interest': 4,
  }

  obj.sort((a, b) => order[a.status] - order[b.status])
  return obj
}

export function sortCountries(a: CountryType, b: CountryType) {
  //sort by sortIndex first, then by name
  let sort = a.sortIndex < b.sortIndex ? -1 : a.sortIndex > b.sortIndex ? 1 : a.name.localeCompare(b.name)
  return sort
}

export function setAddressIndex(input: AddressFormData[], primaryIndex: number, primaryField: 'isPrimary' | 'isBilling'): AddressFormData[] {
  if (primaryIndex > -1) {
    input.forEach((entry: AddressFormData, index) => {
      index === primaryIndex ? (entry[primaryField] = true) : (entry[primaryField] = false)
    })
  } else {
    input.forEach((entry: AddressFormData, index) => {
      index === 0 ? (entry[primaryField] = true) : (entry[primaryField] = false)
    })
  }
  return input
}

export function setPrimaryIndex(input: PhoneFormData[] | EmailResponse[], primaryIndex: string | number): PhoneFormData[] | EmailResponse[] {
  //this function always returns the phones array, it exists as a check to sync the primaryIndex with the input object if the functionality exists. it can be used on entities that do not have primary phone capabilities, as it will always pass the array through it regardless of manipulation
  const primary = typeof primaryIndex === 'string' ? parseInt(primaryIndex) : primaryIndex
  //quick check on primaryPhone to prevent the unnecessary loop if primaryPhone isn't set
  if (primary > -1) {
    input.forEach((entry, index) => {
      //backend sets the primary* to the first object that passes the truthy check. If we dont intentionally change every entry to reflect the primaryCheck, we will get unexpected results

      index === primary ? (entry.isPrimary = true) : (entry.isPrimary = false)
    })
  }

  return input
}

export function getPhoneRequest(phone: PhoneFormData) {
  if (phone !== undefined) {
    // every phone object should include a callingCode field, as it is set by `preparePhoneNumber` for phone records that already exist or set in PhoneNumberField for new entries
    // even if it doesn't come in the response, the PhoneNumberField sets it in the form data from a useEffect after parsing the number
    const newPhone: PhoneRequest = {
      label: phone.label,
      number: `+${phone.callingCode.value.telephoneCode}${phone.number}`,
      isPrimary: phone.isPrimary,
      extension: phone.extension,
    }
    if (phone.id) newPhone.id = phone.id
    return newPhone
  }
}

export function getPhonesRequest(phones: PhoneFormData[]) {
  if (phones === undefined) return
  if (phones.length === 0) return []

  const collection = phones.map(getPhoneRequest)
  setDefaultPrimaryPhone(collection)
  return collection
}

export function removeEmptyId(object: any) {
  // if object has an empty id, the id property should not be sent to the server
  // if it has an id, it already exists in the DB and the server expects the id property
  const { id, ...rest } = object
  if (!id) return rest
  return object
}

export function getNewAddress(fields: Partial<AddressFormData> ) {
  return {
    label: ADDRESS_LABEL.COMPANY_ADDRESS,
    recipient: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    isBilling: undefined,
    isPrimary: undefined,
    ...fields
  } as AddressFormData
}

export function getAddressRequest(address: AddressFormData) {
  const addressLabel = typeof address.label !== 'string' ? address.label?.value : address?.label
  const formattedAddress: AddressRequest = {
    label: addressLabel || ADDRESS_LABEL.COMPANY_ADDRESS,
    recipient: address.recipient,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: address.state.value,
    country: address.country.value,
    postalCode: address.postalCode,
    isBilling: address.isBilling ?? undefined,
    isPrimary: address.isPrimary ?? undefined,
  }

  if (address.id) formattedAddress.id = address.id

  return removeEmptyId(formattedAddress)
}

export function getAddressFormData(address: AddressResponse): AddressFormData {
  return {
    id: address.id,
    label: formatSingleENUM(address.label),
    recipient: address.recipient,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: formatStringOption(address.state),
    country: formatStringOption(address.country),
    postalCode: address.postalCode,
  }
}

// used to strip the stored phone number from the DB into the `callingCode` and `number` to be used as separate inputs in form data fields
export function formatPhoneNumber(phone: PhoneResponse, { countries }: { countries: CountryType[] }) {
  // when parsing without a country code argument, the library can usually parse correctly if the + sign and calling code is present in the string, which should be the case for phone numbers stored in the database
  // if the string does not contain these, the parsing will potentially fail
  try {
    const phoneNumber = countryCodeCheck(phone.number)
    const parsedNumber = parsePhoneNumber(phoneNumber)
    return {
      id: phone.id,
      label: phone.label,
      callingCode: formatCallingCodeOption(countries.find((c) => c.telephoneCode === parsedNumber.countryCallingCode)) ?? null,
      number: parsedNumber.nationalNumber,
      isPrimary: phone.isPrimary ? phone.isPrimary : false,
      extension: phone.extension,
    }
  } catch (err) {
    console.log('Error parsing phone: ', phone)
    console.error(err)
  }
}

//checks if input param includes '+' char. if it does, pass the input param through without manipulation. if it does not, it adds '+1' to the input param. this assumes that all phone numbers without country code are US-based. this was discussed in #engage -> https://fsgproduct.slack.com/archives/C03404ZTXED/p1697488173950169
function countryCodeCheck(inputString: string) {
  if (!inputString.includes('+')) {
    inputString = `+1${inputString}`
  }
  return inputString
}

export function compileRolesArray(
  roleFormData: {
    currentCompany: Option
    currentTitle?: string
    jobFunctions?: JobFunction[]
    isCurrent?: boolean
  },
  roles: ContactRoleResponse[],
): ContactRoleRequest[] {
  // Find the role marked as primary
  // Secondary check to find the latest role in the list just handle bad data where isCurrent is not yet defined
  const primaryRole = roles.find((role) => role.isCurrent === true) ?? (roles.length && roles[roles.length - 1])
  const { currentTitle, currentCompany, jobFunctions: currentJobFunctions } = roleFormData

  if (currentCompany === undefined) return roles

  const selectedAccountId = currentCompany.value
  // If the form data has a different company selected than the id in the `currentRole` object, the user is trying to update the company info
  let accountChanged = selectedAccountId !== primaryRole.accountId

  if (accountChanged) {
    /* CASE 1: User changes the current company
        - create a new role entry in roles array
        - build new role entry with the data in the jobFunctions and title fields
    */
    const newRole: ContactRoleRequest = {
      accountId: selectedAccountId,
      title: currentTitle ?? '',
      jobFunctions: currentJobFunctions ?? [],
      isCurrent: true,
    }
    // Set `isCurrent` on any other roles  to false
    roles.forEach((role) => (role.isCurrent = false))
    return [...roles, newRole]
  } else {
    /* CASE 2: User changes only title or jobFunctions (functional roles in UI)
      - modify only the item being edited in the roles array, which should be the one marked as primary because the UI only displays edit fields for that role
  */
    return roles.map((role, index, array) =>
      role.isCurrent === true ??
      // Secondary check to find the latest role in the list just handle bad data where isPrimary is not yet defined
      index === array.length - 1
        ? // update the primary role with any new data
          { ...role, jobFunctions: currentJobFunctions, title: currentTitle }
        : role,
    )
  }
}

export function getSelectOptionFromBool(trueLabel: string, falseLabel: string, value: boolean) {
  return value ? { label: trueLabel, value } : { label: falseLabel, value }
}

export function setDefaultPrimaryPhone(phones: PhoneRequest[]) {
  if (phones.length === 1) {
    phones[0].isPrimary = true
  }
  return phones
}

export function setDefaultPrimaryEmail(emails: EmailRequest[]) {
  if (emails.length === 1) {
    emails[0].isPrimary = true
  }
  return emails
}

// Used in Select fields
export function formatCountryOption(country: CountryType): SelectOption<CountryType> {
  return {
    label: country.name,
    value: country,
  }
}

// Used in Select fields
export function formatStateOption(state: StateType): SelectOption {
  return { label: state.name, value: state.code }
}

// Used in Select fields
export function formatCallingCodeOption(country: CountryType): SelectOption<CountryType> {
  return {
    label: `+${country.telephoneCode}`,
    value: country,
  }
}

/**
 * @param obj.callingCode - The phone number's country calling code. The value should be typed as the return type of `formatCallingCodeOption`, so that if a change is made to that function, it will be linked to this parameter.
 * @param obj.number - The phone number. Should be a national number format, e.g. XXX-XXX-XXXX.
 * @param obj.extension - The phone number's extension. Should be only the numeric characters.
 */
export function getPhoneDisplayString(data: { callingCode: ReturnType<typeof formatCallingCodeOption>; number: string; extension: string }) {
  const telephoneCode = data.callingCode?.value?.telephoneCode || ''
  const number = data.number
  const extension = data.extension ? `ext. ${data.extension}` : ''

  return `+${telephoneCode} ${number} ${extension}`.trim()
}

export function formatAccountOption(account: AccountResponse) {
  return {
    label: `${account.name}`,
    value: account.id,
    data: account,
  }
}

// TODO: same function in accounts transformer... Replace imports everywhere in separate PR
export function formatPaymentMethodOption(paymentMethod: PaymentMethod) {
  return { label: paymentMethod.name, value: paymentMethod }
}

export const divisionFormatter = (params: any, divisions: Division[]) => {
  const { value } = params
  const div = divisions.find((division: { name: string; number: string }) => value && parseInt(division.number) === parseInt(value))
  const output = div ? `(${div.number}) - ${div.name}` : placeholder
  return output
}
