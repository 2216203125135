// REVIEW >> Make sure you really want to use an enum and not a const.  Watch https://youtu.be/jjMbPt_H3RQ?si=J6age2ILKD50bXEx (TL;DR; at 5:00) #code-review--typescript
export enum ENTITY {
  ACCOUNTS = 'accounts',
  CONTACTS = 'contacts',
  LEADS = 'leads',
  OPPORTUNITIES = 'opportunities',
  SITES = 'sites',
}

// REVIEW >> ...And if we're going to use enums, consider the following pattern: #code-review--typescript
// ? const ENTITY_T = {
// ?   ACCOUNTS: 'accounts',
// ?   CONTACTS: 'contacts',
// ?   LEADS: 'leads',
// ?   OPPORTUNITIES: 'opportunities',
// ? } as const
// ? type ObjectValues<T> = T[keyof T]
// ? export type Entity = ObjectValues<typeof ENTITY_T>
// ? function someEntityFunc(id: string, type: Entity) {
// ?   // console.debug(`${ENTITY_T[type]}: ${id}`)
// ?   // stuff...
// ? }
// ? someEntityFunc('12324', 'accounts')

export enum ACCOUNT_ADDRESS_LABEL {
  BILL_TO = 'BILL_TO',
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  SHIP_TO = 'SHIP_TO',
}

export enum CONTACT_ADDRESS_LABEL {
  HOME_ADDRESS = 'HOME_ADDRESS',
  WORK_ADDRESS = 'WORK_ADDRESS',
}

export enum LEAD_ADDRESS_LABEL {
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  HOME_ADDRESS = 'HOME_ADDRESS',
  WORK_SITE = 'WORK_SITE',
}

export enum SITE_ADDRESS_LABEL {
  BILL_TO = 'BILL_TO',
  SHIP_TO = 'SHIP_TO',
  WORK_SITE = 'WORK_SITE',
}

//combine all address labels
export enum ADDRESS_LABEL {
  BILL_TO = 'BILL_TO',
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  SHIP_TO = 'SHIP_TO',
  WORK_SITE = 'WORK_SITE',
  WORK_ADDRESS = 'WORK_ADDRESS',
  HOME_ADDRESS = 'HOME_ADDRESS',
}